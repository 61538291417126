<script setup lang="ts">
import { ValidationObserver } from 'vee-validate'
import { ref, computed } from 'vue'
import { useRoute, useRouter } from 'vue-router/composables'
import ui from '/~/core/ui'
import BaseButton from '/~/components/base/button/base-button.vue'
import BaseInput from '/~/components/base/input/base-input.vue'
import { useAuth } from '/~/composables/auth'
import { useForm } from '/~/composables/base/use-form'
import { useProvider } from '/~/composables/provider'
import AuthLayout from '/~/layouts/auth/layout-auth.vue'
import AuthLogo from '/~/views/auth/auth-logo.vue'

const router = useRouter()
const route = useRoute()

const { providerTitle } = useProvider()
const { createPassword } = useAuth()
const { validationObserverRef } = useForm()

const processing = ref<boolean>(false)
const password = ref<string>('')
const repeatPassword = ref<string>('')

const submitDisabled = computed(
  () =>
    validationObserverRef.value?.flags.invalid ||
    !password.value.length ||
    !repeatPassword.value.length
)

async function onSubmit() {
  processing.value = true
  const payload = {
    password: password.value,
    confirmPassword: repeatPassword.value,
  }

  try {
    const { data } = await createPassword(route.params?.token, payload)

    if (!data) {
      return
    }

    router.push({ name: 'auth-login' })
  } catch (error) {
    console.error('auth', error)
  } finally {
    processing.value = false
  }
}
</script>

<template>
  <auth-layout>
    <auth-logo v-if="ui.mobile" class="mb-2" />
    <validation-observer
      v-slot="{ handleSubmit }"
      ref="validationObserverRef"
      slim
    >
      <form
        class="mx-auto w-full max-w-xl p-5 sm:mt-20 sm:p-2.5"
        @submit.prevent="handleSubmit(onSubmit)"
      >
        <h2 class="text-center text-2xl text-eonx-neutral-800">
          Create a password to access {{ providerTitle }}
        </h2>
        <div class="mt-8">
          <span class="pr-[5px] font-bold text-eonx-neutral-800">
            Registered email:
          </span>
          <span class="text-eonx-neutral-600">{{ route.query.email }}</span>
        </div>

        <div class="mt-[22px] sm:flex sm:space-x-5">
          <base-input
            ref="new-password"
            v-model="password"
            :validation="{
              rules: 'required|password',
              name: 'Password',
              vid: 'password',
            }"
            :disabled="processing"
            name="password"
            label="Password"
            type="password"
            required
            vid="password"
            autocomplete="new-password"
            class="sm:w-1/2"
          />
          <base-input
            v-model="repeatPassword"
            :validation="{
              rules: 'required|confirmed:@password,Password',
              name: 'Confirm Password',
              mode: 'aggressive',
            }"
            :disabled="processing"
            name="repeatPassword"
            label="Confirm Password"
            type="password"
            required
            vid="confirmation"
            class="sm:w-1/2"
          />
        </div>
        <base-button
          :disabled="submitDisabled"
          :loading="processing"
          class="mx-auto mt-[11px] w-full sm:mt-[19px]"
          type="submit"
        >
          Confirm
        </base-button>
      </form>
    </validation-observer>
  </auth-layout>
</template>
